import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../supabase/client";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

const login = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  if (error) throw error;
  const { user, session } = data;
  if (session) {
    localStorage.setItem("accessToken", session.access_token);
    localStorage.setItem("refreshToken", session.refresh_token);
  }
  return { user, session };
};

const signOut = async () => {
  await supabase.auth.signOut();
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken && refreshToken) {
        const { data: session, error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken,
        });
        if (!error) {
          setUser(session.user);
          setAuth(true);
        }
      }
      setLoading(false);
    };

    initializeAuth();

    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setUser(session.user);
        setAuth(true);
        localStorage.setItem("accessToken", session.access_token);
        localStorage.setItem("refreshToken", session.refresh_token);
      } else if (event === "SIGNED_OUT") {
        setUser(null);
        setAuth(false);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ auth, user, login, signOut }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
