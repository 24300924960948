import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import blackandwhite from "../assets/blackandwhite.jpg";

export function AboutView() {
  return (
    <div>
      <div className="bg-white px-6 py-12 md:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            The origins of this project
          </h1>
          <p className="mt-6 text-xl leading-8">
            After biking 18,000 miles, climbing 750,000 ft, over the course of
            411 days, I became the first person to bike to all of the National
            Parks in the lower 48 states. All of that time alone in the saddle
            led me to create this website.
          </p>
          <div className="mt-10 max-w-2xl">
            <p>
              In 2023, I set out on a{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://onelongtrip.bike/"
                target="_blank"
              >
                bicycle tour to all of the National Parks
              </a>
              &nbsp;in the lower 48 states, hoping to become the first person to
              do this link-up.
            </p>
            <p className="mt-8">
              During my trip, I realized how little support there is for people
              who are cycle touring in America. If you happen to be on an
              established route like the TransAm, there will be some support,
              but if you’re on your own course you’re going to find very little.
              The support that does exist is incredibly difficult to find.{" "}
              <a
                target="_blank"
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://exploringwild.com/hiker-biker-campsites/"
              >
                Hiker/biker campsites
              </a>{" "}
              don’t show up on Google Maps, and state parks that do have them
              bury them on their website if they have them at all. Even the
              employees of these parks are not clear on what is available.
            </p>
            <p className="mt-8">
              That’s where GoBikeCamping.com comes in. Our goal is to aggregate
              all of this data in one place so that it easy for people to find
              while out on tour, or planning one from home. We do the research
              so that you don’t have to. We also give you the ability to
              check-in and update campsite information so that those who come
              after you know when a campsite is closed, the water is no longer
              potable, or the electricity doesn’t work anymore.
            </p>
          </div>
          <figure className="mt-16">
            <img
              alt="Spencer with bike in Missoula. A portrait."
              src={blackandwhite}
              className="rounded-xl bg-gray-50 object-cover"
            />
            <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
              <InformationCircleIcon
                aria-hidden="true"
                className="mt-0.5 h-5 w-5 flex-none text-gray-300"
              />
              Visiting the ACA HQ in Missoula, MT.
            </figcaption>
          </figure>
          <div className="mt-16 max-w-2xl">
            {/* <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Everything you need to get up and running
            </h2> */}
            <p className="mt-6">
              Beyond this website we are also advocating for the creation of
              more hiker/biker campsites. Our ultimate goal is that every
              publicly owned campground in America will have a reasonably priced
              hiker/biker section. Cycling is exploding in this country, while
              at the same time our public lands are becoming full of cars.
              Accommodating cyclists will help us cut down on traffic in our
              public lands and return them to the state of parks, not highways.
            </p>
            <p className="mt-8">
              You can hear more about my trip and this project on these two
              podcasts:
            </p>
            <div className="mt-8">
              <iframe
                style={{ borderRadius: "12px" }}
                src="https://open.spotify.com/embed/episode/4Wt8WEqcKXokJsNO9pR8vw?utm_source=generator"
                width="100%"
                height="152"
                frameBorder="0"
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
              <br />
              <iframe
                style={{ borderRadius: "12px" }}
                src="https://open.spotify.com/embed/episode/0zhEuRZnpUPgtSQBO72Bwj?utm_source=generator"
                width="100%"
                height="152"
                frameBorder="0"
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
            <p className="mt-8">Happy trails,</p>
            <p>Spencer</p>
          </div>
        </div>
      </div>
    </div>
  );
}
