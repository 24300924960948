import React, { useEffect, useState } from "react";

import { DonateView } from "./Routes/DonateView";
import { LoginView } from "./Routes/LoginView";
import { LandingView } from "./Routes/LandingView";
import { RegisterView } from "./Routes/RegisterView";
import { AboutView } from "./Routes/AboutView";
import { AddNewView } from "./Routes/AddNewView";
import { RequestsView } from "./Routes/RequestsView";
import { CheckinView } from "./Routes/CheckinView";
import { PostRegistrationView } from "./Routes/PostRegistrationView";
import { CampsiteView } from "./Routes/CampsiteView";
import { MapView } from "./Routes/MapView";
import { PageNotFoundView } from "./Routes/PageNotFoundView";
import { NoTurnAwayView } from "./Routes/NoTurnAwayView";
import { CampingCoalitionView } from "./Routes/CampingCoalitionView";
import RootView from "./Routes/RootView";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import { supabase } from "./supabase/client";
import AuthRoute from "./Components/routeGuards/AuthRoute";
import AnonRoute from "./Components/routeGuards/AnonRoute";

const App = () => {
  const [allCampsites, setAllCampsites] = useState({ features: [] });
  const [isLoading, setLoading] = useState(true);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<RootView />}>
          <Route
            path="/"
            element={
              <LandingView allCampsites={allCampsites} isLoading={isLoading} />
            }
          />
          <Route
            path="/map"
            element={<MapView allCampsites={allCampsites} />}
          />
          <Route path="*" element={<PageNotFoundView />} />
          <Route path="/welcome" element={<PostRegistrationView />} />
          <Route path="/donate" element={<DonateView />} />
          <Route path="/requests" element={<RequestsView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/noturnaway" element={<NoTurnAwayView />} />
          <Route path="/coalition" element={<CampingCoalitionView />} />
          <Route
            path="/campsite/:campsiteComposite"
            element={<CampsiteView />}
          />
        </Route>
        <Route element={<AuthRoute />}>
          <Route element={<RootView />}>
            <Route path="/checkin" element={<CheckinView />} />
            <Route
              path="/addNew"
              element={<AddNewView allCampsites={allCampsites} />}
            />
          </Route>
        </Route>
        <Route element={<AnonRoute />}>
          <Route element={<RootView />}>
            <Route path="/login" element={<LoginView />} />
            <Route path="/signup" element={<RegisterView />} />
          </Route>
        </Route>
      </Route>
    )
  );

  useEffect(() => {
    getAllCampsites();
  }, []);

  async function getAllCampsites() {
    const { data } = await supabase
      .from("campsites")
      .select()
      .eq("approved", true);
    let geojson = { features: [], type: "FeatureCollection" };
    for (let i = 0; i < data.length; i++) {
      let tempCampsite = {
        type: "Feature",
        properties: { ...data[i] },
        geometry: {
          coordinates: [data[i].longitude, data[i].latitude],
          type: "Point",
        },
        id: data[i].id.toString(),
      };
      geojson.features.push(tempCampsite);
    }

    setLoading(false);
    setAllCampsites(geojson);
  }

  return (
    <AuthProvider>
      <RouterProvider router={router}></RouterProvider>
    </AuthProvider>
  );
};
export default App;
