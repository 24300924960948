import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { supabase } from "../supabase/client";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

export function CampsiteView() {
  const { campsiteComposite } = useParams();
  const [campsiteData, setCampsiteData] = useState({
    latitude: 0,
    longitude: 0,
    checkins: [],
  });
  const paramsArr = campsiteComposite.split("-");
  const paramId = paramsArr[0];
  const paramName = paramsArr[1];
  const navigate = useNavigate();

  async function fetchCampsiteData() {
    const { data, error } = await supabase
      .from("campsites")
      .select(
        `*,
            checkins (
                *,
                profiles (
                    username
                )
            )
        `
      )
      .eq("id", paramId);
    //   .order("checkins(check_in_date)", { ascending: false });

    if (error) {
      console.error("Error fetching campsites:", error);
    } else {
      // couldn't figure out how to sort the checkins in the query so i'm doing it here
      data[0].checkins.sort(
        (a, b) => new Date(b.check_in_date) - new Date(a.check_in_date)
      );
      setCampsiteData(data[0]);
    }
  }

  function costFormatter(val) {
    if (val === 0) {
      if (campsiteData.donation_based) {
        return "Donation";
      } else {
        return "Free";
      }
    } else if (val) {
      if (campsiteData.cost_unit) {
        return "$" + val + "/" + campsiteData.cost_unit;
      } else {
        return "$" + val;
      }
    }
  }

  function unknownFormatter(val) {
    if (val) {
      return "yes";
    } else if (val === null || val === undefined) {
      return "unknown";
    } else {
      return "no";
    }
  }

  function unknownFormatterOptions(val) {
    if (val === null || val === undefined) {
      return "unknown";
    } else {
      return val;
    }
  }

  function websiteFormatter(val) {
    if (val) {
      return (
        <a
          className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          target="_blank"
          href={val}
        >
          URL
        </a>
      );
    } else {
      return "n/a";
    }
  }

  function phoneFormatter(val) {
    if (val) {
      return (
        <a
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
          href={"tel:" + val}
        >
          {val.slice(0, 3) + "-" + val.slice(3, 6) + "-" + val.slice(6)}
        </a>
      );
    } else {
      return "unknown";
    }
  }

  function noTurnAwayBadge(val) {
    if (val) {
      return (
        <span className="align-middle inline-flex items-center rounded-md bg-purple-50 ml-5 px-2 py-1 text-lg font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
          <Link to="/noturnaway">No Turn Away</Link>
        </span>
      );
    }
  }

  const goToCheckinPage = () => {
    navigate("/checkin", { state: { properties: campsiteData } });
  };

  useEffect(() => {
    fetchCampsiteData();
  }, []);
  return (
    <div>
      <div className="bg-white px-6 py-14 lg:px-8 w-full max-w-7xl mx-auto">
        <div className="mx-auto text-base leading-7 text-gray-700">
          <div className="mb-4">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {paramName}
              {noTurnAwayBadge(campsiteData.no_turn_away)}
            </h1>
            <p>{campsiteData.state}</p>
          </div>
          {/* 3 column wrapper */}
          <div className="mx-auto w-full max-w-7xl grow">
            {/* Left sidebar & main wrapper */}
            <div className="grid md:grid-cols-4 grid-cols-1">
              <div className="pr-4 col-span-1">
                {/* Left column area */}
                <div>
                  <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                    Details
                  </h3>
                  <p>
                    <span className="font-bold">Cost:</span>{" "}
                    {costFormatter(campsiteData.cost)}
                  </p>
                  <p>
                    <span className="font-bold">Type of Facility:</span>{" "}
                    {campsiteData.type_of_facility}
                  </p>
                  <p>
                    <span className="font-bold">GPS:</span>{" "}
                    <a
                      className="cursor-pointer underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                      target="_blank"
                      href={
                        "https://www.google.com/maps/place/" +
                        campsiteData.latitude +
                        "," +
                        campsiteData.longitude
                      }
                    >
                      {campsiteData.latitude.toString().slice(0, 7) +
                        "," +
                        campsiteData.longitude.toString().slice(0, 8)}
                    </a>
                  </p>
                  <p>
                    <span className="font-bold">Website:</span>{" "}
                    {websiteFormatter(campsiteData.website_url)}
                  </p>
                  <p>
                    <span className="font-bold">Phone:</span>{" "}
                    {phoneFormatter(campsiteData.phone_number)}
                  </p>
                </div>
                <div>
                  <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                    Amenities
                  </h3>
                  <p>
                    <span className="font-bold">Picnic Tables:</span>{" "}
                    {unknownFormatter(campsiteData.tables)}
                  </p>
                  <p>
                    <span className="font-bold">Picnic Shelter:</span>{" "}
                    {unknownFormatter(campsiteData.picnic_shelter)}
                  </p>
                  <p>
                    <span className="font-bold">Water:</span>{" "}
                    {unknownFormatterOptions(campsiteData.water)}
                  </p>
                  <p>
                    <span className="font-bold">Showers:</span>{" "}
                    {unknownFormatterOptions(campsiteData.showers)}
                  </p>
                  <p>
                    <span className="font-bold">Toilet:</span>{" "}
                    {unknownFormatterOptions(campsiteData.toilet)}
                  </p>
                  <p>
                    <span className="font-bold">Electricity:</span>{" "}
                    {unknownFormatterOptions(campsiteData.electricity)}
                  </p>
                  <p>
                    <span className="font-bold">Hammock:</span>{" "}
                    {unknownFormatter(campsiteData.hammocks)}
                  </p>
                  <p>
                    <span className="font-bold">Laundry:</span>{" "}
                    {unknownFormatter(campsiteData.laundry)}
                  </p>
                  <p>
                    <span className="font-bold">Wifi:</span>{" "}
                    {unknownFormatter(campsiteData.wifi)}
                  </p>
                  <p>
                    <span className="font-bold">Food Storage Container:</span>{" "}
                    {unknownFormatter(campsiteData.food_storage)}
                  </p>
                </div>
                <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                  Something wrong?
                </h3>
                <a
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  href={
                    "mailto:gobikecamping@gmail.com?subject=Report for Campsite: " +
                    campsiteData.id
                  }
                >
                  Report it
                </a>
              </div>

              <div className="md:px-4 mt-5 md:mt-0 col-span-2 md:max-w-xl">
                <div>
                  {campsiteData.advisory ? (
                    <div className="rounded-md bg-yellow-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationTriangleIcon
                            className="h-5 w-5 text-yellow-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-yellow-800">
                            Attention needed
                          </h3>
                          <div className="mt-2 text-sm text-yellow-700">
                            <p>{campsiteData.advisory}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                    Description
                  </h3>
                  <p>{campsiteData.description}</p>
                </div>
              </div>
              <div className="md:pl-4 mt-5 md:mt-0 col-span-1">
                {/* Right column area */}
                <h3 className="mt-2 text-2xl font-medium tracking-tight text-gray-900 mb-2">
                  Check-ins
                </h3>
                <ul role="list" className="grid grid-cols-1 gap-6">
                  {campsiteData.checkins.map((checkin) => (
                    <li
                      key={checkin.id}
                      className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                    >
                      <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1">
                          <div className="flex items-center space-x-3">
                            <h3 className="text-sm font-medium text-gray-900">
                              {checkin.check_in_date}
                            </h3>
                            <span className="inline-flex flex-shrink-0 items-center rounded-full px-0.5 py-0.5 text-xs font-medium">
                              by: {checkin.profiles.username}
                            </span>
                          </div>
                          <p className="mt-1 text-sm text-gray-500">
                            {checkin.description}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                {campsiteData.checkins.length > 0 ? (
                  <button
                    onClick={() => goToCheckinPage()}
                    class="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Check-in here
                  </button>
                ) : (
                  <div>
                    <p>No one has checked-in yet.</p>
                    <button
                      onClick={() => goToCheckinPage()}
                      class="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Be the first
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
