import peopleForBikes from "../assets/partner_logos/People_for_bikes_logo.jpg";
import ecgAlliance from "../assets/partner_logos/ecg-logo-alliance.svg";
import bprLogo from "../assets/partner_logos/BPR_Logo_square_transparent.png";
import dblLogo from "../assets/partner_logos/dbl_blue.png";
import rarLogo from "../assets/partner_logos/RARFrontRange.png";
import treehouseLogo from "../assets/partner_logos/treehouse-logo.png";
import bikestreets from "../assets/partner_logos/bikestreets.png";
import whitebarkgear from "../assets/partner_logos/whitebark-gear.png";
import totemcyclery from "../assets/partner_logos/totem.jpg";
import ovejaNegra from "../assets/partner_logos/ovejaNegra.jpeg";

const logos = [
  {
    name: "People for Bikes",
    url: "https://www.peopleforbikes.org/",
    imageUrl: peopleForBikes,
    representation: 1500000,
  },
  {
    name: "The East Coast Greenway Alliance",
    url: "https://greenway.org/",
    imageUrl: ecgAlliance,
    representation: 0,
  },
  {
    name: "Bikepacking Roots",
    url: "https://bikepackingroots.org/",
    imageUrl: bprLogo,
    representation: 17000,
  },
  {
    name: "Denver Bicycle Lobby",
    url: "https://denverbicyclelobby.com/",
    imageUrl: dblLogo,
    representation: 2000,
  },
  {
    name: "Treehouse Cyclery",
    url: "https://www.treehousecyclery.com/",
    imageUrl: treehouseLogo,
    representation: 5000,
  },
  {
    name: "RaR Front Range",
    url: "https://radicaladventureriders.com/chapters/front-range",
    imageUrl: rarLogo,
    representation: 200,
  },
  {
    name: "Bike Streets",
    url: "https://bikestreets.com/",
    imageUrl: bikestreets,
    representation: 0,
  },
  {
    name: "Totem Cyclery",
    url: "https://www.totemcyclery.com/",
    imageUrl: totemcyclery,
    representation: 1843,
  },
  {
    name: "Whitebark Gear",
    url: "https://www.whitebarkgear.com/shop",
    imageUrl: whitebarkgear,
    representation: 16000,
  },
  {
    name: "Oveja Negra",
    url: "https://www.ovejanegrabikepacking.com/",
    imageUrl: ovejaNegra,
    representation: 26800,
  },
];

const totalRepresentation = () => {
  let total = 0;
  for (let i = 0; i < logos.length; i++) {
    total += logos[i].representation;
  }

  return total.toLocaleString() + "+";
};

const stats = [
  { id: 1, name: "Coalition Partners", value: logos.length },
  { id: 2, name: "Cyclists Represented", value: totalRepresentation() },
];

export function CampingCoalitionView() {
  return (
    <div>
      <div className="bg-white pt-24 pb-12 sm:pt-32 sm:pb-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Better Camping, Together!
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-600">
                Bringing together the cycling community to advocate for the
                camping options that we need.
              </p>
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              <div className="hidden lg:inline-block"></div>
              {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                  <dt className="text-sm font-semibold leading-6 text-gray-600">
                    {stat.name}
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                    {stat.value}
                  </dd>
                </div>
              ))}
              <div className="hidden lg:inline-block"></div>
            </dl>
          </div>
        </div>
      </div>
      <div className="px-5 pt-12 sm:pt-16 mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <div>
          <p>
            <b>
              The goal of the Bicycle Camping Coalition is to make sure that
              every publicly run campground in the US has a hiker/biker campsite
              and a no-turn-away policy.
            </b>{" "}
            There are a lot of things to worry about when biking, where you're
            going to sleep shouldn't be one of them.
          </p>
          <p className="mt-6">
            There are so many amazing organizations in the US that are
            advocating for cycling. Whether that's for cycling access on public
            lands, safer streets, or the ability to bring our bikes on the
            train. It's important work. The one place no one has the time and
            resources to completely focus on are camping options for cyclists.
          </p>
          <p className="mt-6">
            It's silly that 1 cyclist usually gets charged the same price as a
            family of 6 in an RV when at a campground. While on your bike, it's
            also pretty tough to commit to a camping reservation. Mechanicals,
            head winds, and thunderstorms can totally derail your plans. We're
            tired of it. We're bringing together all of the organizations that
            love the outdoors and non-motorized recreation to get the
            representation that we need in America's campgrounds.
          </p>
        </div>
      </div>
      <div className="my-24 bg-indigo-100">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Want to help?
            <br />
            Join the coalition today.
          </h2>
          <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
            <a
              target="_blank"
              href="https://forms.gle/7E7cHa7qb8YoWfRA8"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              I'm an individual
            </a>
            <a
              target="_blank"
              href="https://forms.gle/PantzDs5AaGRfjNY7"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              For Organizations <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>
      <div className="bg-white pb-24 sm:pb-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl md:max-w-none">
            <h2 className="text-lg font-semibold leading-8 text-gray-900">
              Meet our partners:
            </h2>
            <div className="items-center mx-auto mt-10 grid grid-cols-3 items-start gap-x-8 gap-y-10 sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:grid-cols-5">
              {logos.map((logo, index) => {
                return (
                  <a target="_blank" href={logo.url} key={index}>
                    <img
                      alt={logo.name}
                      src={logo.imageUrl}
                      className="col-span-2 max-h-32 w-full object-contain lg:col-span-1"
                    />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
