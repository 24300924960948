import React, { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { isMobile } from "react-device-detect";
import "../../styles/FilterControlStyles.css";

export default function CampsiteInfoDrawer({
  campsiteInfoOpen,
  setCampsiteInfoOpen,
  properties,
  coordinates,
}) {
  const [checkins, setCheckins] = useState(null);
  // stops rerendering when off screen. not sure why it is happening.
  if (!campsiteInfoOpen) {
    return;
  }

  const hasWater = properties.water === true;
  const hasTables = properties.tables === true;
  const hasShowers =
    properties.showers !== undefined && properties.showers !== "no";
  const hasToilet =
    properties.toilet !== undefined && properties.toilet !== "no";
  const hasFoodStorage = properties.food_storage === true;
  const hasElectricity =
    properties.electricity !== null && properties.electricity !== "no";
  let hasAdvisory = false;

  if (properties.advisory) {
    hasAdvisory = true;
  }

  function parsedDesc(desc) {
    var regex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/g;
    if (desc) {
      return desc.replace(
        regex,
        '<a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:$&">$&</a>'
      );
    }
  }

  // const toiletType = properties.toilet;
  // let hasToilet = false;

  // if (toiletType && toiletType !== true) {
  //   hasToilet = true;
  // }

  const siteCost = properties.cost;
  const siteCostUnit = properties.cost_unit;
  let CostTag = "";

  if (properties.donation_based === true) {
    CostTag = "<p><b>Cost: </b>Donation</p>";
  } else if (isNaN(siteCost)) {
    CostTag = "<p><b>Cost: </b>unsure</p>";
  } else if (siteCost > 0) {
    CostTag = `<p><b>Cost: </b>$${siteCost}/${siteCostUnit}</p>`;
  } else {
    CostTag = "<p><b>Cost: </b>free</p>";
  }
  return (
    <div>
      <div>
        {/* Mobile filter dialog */}
        <Dialog
          className="relative z-9999999"
          open={campsiteInfoOpen}
          onClose={() => setCampsiteInfoOpen(false)}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xl transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="font-bold text-base px-2 py-3 sm:p-3">
                  {properties.name}
                  {/* We use less vertical padding on card headers on desktop than on body sections */}
                  <span className="font-normal">
                    {" "}
                    ({properties.type_of_facility})
                  </span>
                </h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                  onClick={() => setCampsiteInfoOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <div className="px-4 py-5 sm:p-6 display-linebreak">
                {hasAdvisory ? (
                  <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon
                          className="h-5 w-5 text-yellow-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">
                          Attention needed
                        </h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>{properties.advisory}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <p
                  style={{ marginTop: "10px" }}
                  dangerouslySetInnerHTML={{
                    __html: parsedDesc(properties.description),
                  }}
                />
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  target="_blank"
                  href={
                    "https://www.google.com/maps/place/" +
                    coordinates[1] +
                    "," +
                    coordinates[0]
                  }
                >
                  Map
                </a>

                {properties.phone_number ? (
                  <span>
                    <br />

                    <a
                      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                      href={"tel:" + properties.phone_number}
                    >
                      {properties.phone_number.slice(0, 3) +
                        "-" +
                        properties.phone_number.slice(3, 6) +
                        "-" +
                        properties.phone_number.slice(6)}
                    </a>
                  </span>
                ) : (
                  ""
                )}

                {properties.website_url ? (
                  <span>
                    <br />

                    <a
                      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                      target="_blank"
                      href={properties.website_url}
                    >
                      Website
                    </a>
                  </span>
                ) : (
                  ""
                )}
                <br />
                <span dangerouslySetInnerHTML={{ __html: CostTag }}></span>

                {properties.submitted_by ? (
                  <span>
                    <br />
                    Submitted by: {properties.submitted_by}
                  </span>
                ) : (
                  ""
                )}
                {/* <br />
            <a
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              href="javascript:;"
              onClick={() => {
                navigator.clipboard.writeText(
                  coordinates[1] + "," + coordinates[0]
                );
              }}
            >
              Copy Coordinates
            </a> */}
              </div>
              <div className="flex items-center justify-center bg-white p-3">
                <div className="flex flex-wrap justify-center gap-x-6 gap-y-4">
                  {hasWater ? (
                    <span className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                      Water
                    </span>
                  ) : (
                    ""
                  )}
                  {hasShowers ? (
                    <span className="inline-flex items-center rounded-full bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                      Showers
                    </span>
                  ) : (
                    ""
                  )}
                  {hasElectricity ? (
                    <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                      Electricity
                    </span>
                  ) : (
                    ""
                  )}
                  {hasTables ? (
                    <span className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-800 ring-1 ring-inset ring-indigo-600/20">
                      Tables
                    </span>
                  ) : (
                    ""
                  )}
                  {hasToilet ? (
                    <span className="inline-flex items-center rounded-full bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
                      {properties.toilet}
                    </span>
                  ) : (
                    ""
                  )}
                  {hasFoodStorage ? (
                    <span className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10">
                      Food Storage Bin
                    </span>
                  ) : (
                    ""
                  )}
                  {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
                </div>
              </div>
              <div className="text-center pt-5">
                {/* <h3 className="text-3xl tracking-tight text-gray-900">
                  No one has checked-in yet.
                </h3> */}
                {isMobile ? (
                  <Link
                    key={properties.id}
                    target={"_blank"}
                    to={"/campsite/" + properties.id + "-" + properties.name}
                    className="mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    See More
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
