import {
  MapIcon,
  HandThumbUpIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const features = [
  {
    name: "Find a campsite",
    description:
      "Head over to the map. Find some campsites right now, or start planning your future tour.",
    href: "/",
    icon: MapIcon,
    linkText: "Find a Campsite",
  },
  {
    name: "Feature Requests",
    description:
      "Tell us how we can make this site better. We want to build something that you'll love. Open new feature requests or vote on existing ones to help us prioritize the work.",
    href: "/requests",
    icon: HandThumbUpIcon,
    linkText: "Feature Requests",
  },
  {
    name: "Our Story",
    description:
      "Learn about how this project got started, how we select campsites, and where we're headed in the future.",
    href: "/about",
    icon: BookOpenIcon,
    linkText: "Our Story",
  },
];

export function PostRegistrationView() {
  return (
    <div>
      <div className="bg-white px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Welcome to GoBikeCamping!
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  To help you get started, we've listed out some of the ways you
                  can engage with the community. Take a look around.
                </p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="flex flex-col">
                      <dt className="text-base font-semibold leading-7 text-gray-900">
                        <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                          <feature.icon
                            aria-hidden="true"
                            className="h-6 w-6 text-white"
                          />
                        </div>
                        {feature.name}
                      </dt>
                      <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                        <p className="flex-auto">{feature.description}</p>
                        <p className="mt-6">
                          <Link
                            to={feature.href}
                            className="text-sm font-semibold leading-6 text-indigo-600"
                          >
                            {feature.linkText} <span aria-hidden="true">→</span>
                          </Link>
                        </p>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
