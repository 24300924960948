import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import hikerbiker from "../assets/hiker-biker.jpg";
import pdfs from "../assets/all-no-turn-away-policies-aug2024.pdf";
import arkansasPdf from "../assets/Arkansas-No-Turn-Away-Policy_2018.pdf";
import floridaPdf from "../assets/Florida-no-turn-away-policy.pdf";
import massPdf from "../assets/MassParks-No-Turn-Away-policy.pdf";
import missouriPdf from "../assets/Missouri-State-Parks_No-Turn-Away.pdf";
import mnPdf from "../assets/MNStateParks_no-turn-awaypolicy.pdf";
import nvPdf from "../assets/NV-State-Parks_No-Turn-Away-Policy.pdf";
import virginiaPdf from "../assets/Virginia-State-Parks-No-Turn-Away-policy.pdf";
import waPdf from "../assets/WA-State-Parks_PRO-65-8-NoTurnAway-Policy-2015.pdf";
import wisconsinPdf from "../assets/Wisconsin-no-turn-away-policy.pdf";

export function NoTurnAwayView() {
  return (
    <div>
      <div className="bg-white px-6 py-12 md:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Parks with No-Turn-Away Policies
          </h1>
          <p className="mt-6 leading-8">
            Camping reservations are requiring more commitment, and very rarely
            do campsites seem to have any availability today. But when you’re
            out on tour, it’s hard to commitment to a reservation. Bad weather
            or a mechanical can throw your whole day off. When a park has a
            no-turn-away policy, that means if you or your small group arrives
            by bike without a reservation and they are full, they’ll find a spot
            in the park for you.
          </p>
          <p className="mt-6 leading-8">
            These aren’t hiker/biker campsites. They’re emergency plans that
            allow you camp wherever the park has designated for this scenario.
            Sometimes it’s under a lunch pavilion, other times it’s a grassy
            area near the bathroom. The camp host will be able to tell you.
          </p>
          <figure className="mt-16">
            <img
              alt="Spencer sharing a hiker/biker campsite with other people."
              src={hikerbiker}
              className="aspect-video rounded-xl bg-gray-50 object-cover"
            />
            <figcaption className="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
              <InformationCircleIcon
                aria-hidden="true"
                className="mt-0.5 h-5 w-5 flex-none text-gray-300"
              />
              Hiker/biker site in Glacier NP.
            </figcaption>
          </figure>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
            Download the policies
          </h2>
          <p className="mt-6 leading-8">
            IMPORTANT: A lot of park employees are not aware of these policies.
            Please download the policies and bring them with you so you can show
            them if you have any issues.{" "}
            <Link
              to={pdfs}
              target="_blank"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
            >
              Download all Policies.
            </Link>
          </p>
          <p className="mt-6">
            <div className="mb-2">
              <Link
                to={arkansasPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Arkansas
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to={floridaPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Florida
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to={massPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Massachusetts
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to="https://www.michigan.gov/dnr/places/state-parks/rules/#section-title-14-3"
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Michigan
              </Link>
              <p className="italic">
                (scroll down to "Considerations for self-supporting/vehicle-free
                camping")
              </p>
            </div>
            <div className="mb-2">
              <Link
                to={mnPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Minnesota
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to={nvPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Nevada
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to={missouriPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Missouri
              </Link>
              <p className="italic">
                (also online:{" "}
                <Link
                  to="https://mostateparks.com/page/54939/camping-rules-and-regulations"
                  target="_blank"
                  className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                >
                  Camping rules and regulations
                </Link>
                )
              </p>
            </div>
            <div className="mb-2">
              <Link
                to="https://tnstateparks.com/about/policies"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Tennessee
              </Link>
              <p className="italic">
                (scroll down to "Bicycle Tourist Camping")
              </p>
            </div>
            <div className="mb-2">
              <Link
                to={virginiaPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Virginia
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to={waPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Washington State
              </Link>
            </div>
            <div className="mb-2">
              <Link
                to={wisconsinPdf}
                target="_blank"
                className="text-xl font-semibold leading-6 text-indigo-600 hover:text-indigo-500 underline"
              >
                Wisconsin
              </Link>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
