import emptyRoad from "../assets/emptyRoad.JPG";
import { Link } from "react-router-dom";

export function PageNotFoundView() {
  return (
    <main
      className="relative isolate h-4/5"
      style={{
        backgroundImage: `url(${emptyRoad})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <p className="drop-shadow-2xl sm:text-3xl text-xl font-semibold leading-8 text-white">
          404
        </p>
        <h1 className="drop-shadow-lg mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
          Page not found
        </h1>
        <p className="drop-shadow-lg mt-4 text-lg text-white sm:mt-6">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10 flex justify-center">
          <Link
            to="/"
            className="drop-shadow-lg text-lg font-semibold leading-7 text-white"
          >
            <span aria-hidden="true">&larr;</span> Back to home
          </Link>
        </div>
      </div>
    </main>
  );
}
