import React, { useCallback } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import NavBar from "../Components/NavBar.js";
import Footer from "../Components/Footer.js";

const RootView = () => {
  const getKey = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ScrollRestoration getKey={(key) => getKey()} />
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default RootView;
