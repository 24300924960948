import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { supabase } from "../supabase/client";
import { useAuth } from "../context/AuthProvider";
import { isMobile } from "react-device-detect";
import GeocoderControl from "../Components/MapComponents/GeocoderControl";
import Map, {
  NavigationControl,
  GeolocateControl,
  Source,
  Marker,
  Popup,
  Layer,
} from "react-map-gl";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Radio,
  RadioGroup,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import "../styles/App.css";

const tableOptions = ["?", "Yes", "No"];
const shelterOptions = ["?", "Yes", "No"];
const donationOptions = ["?", "Yes", "No"];
const waterOptions = [
  "?",
  "Potable",
  "Non-potable",
  "Natural Source",
  "Yes",
  "No",
];
const waterSeasonalOptions = ["?", "Yes", "No"];
const showerOptions = ["?", "Hot", "Warm", "Cold", "Yes", "No"];
const toiletOptions = [
  "?",
  "Running Water",
  "Pit Toilet",
  "Porta Potty",
  "Yes",
  "No",
];
const trashServiceOptions = ["?", "Yes", "No"];
const electricityOptions = [
  "?",
  "Yes",
  "Yes - at sites",
  "Yes - not at sites",
  "No",
];
const laundryOptions = ["?", "Yes", "No"];
const foodStorageOptions = ["?", "Yes", "No"];
const noTurnAway = ["?", "Yes", "No"];
const wifiOptions = [
  "?",
  "Yes - fast",
  "Yes - average",
  "Yes - slow",
  "Yes - unknown",
  "No",
];
const hammockOptions = ["?", "Yes", "No"];
const states = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
];

const costUnit = [
  { name: "Unsure", value: null },
  { name: "Site", value: "site" },
  { name: "Person", value: "person" },
  { name: "Tent", value: "tent" },
];

const typeOfFacility = [
  { name: "Unsure", value: null },
  { name: "Miscellaneous", value: "miscellaneous" },
  { name: "Campground", value: "campground" },
  { name: "H/B Campground", value: "h/b campground" },
  { name: "Park", value: "park" },
  { name: "RV Park", value: "rv park" },
  { name: "Dispersed", value: "dispersed" },
  { name: "Business", value: "business" },
  { name: "Church", value: "church" },
  { name: "Hostel", value: "hostel" },
  { name: "Hotel", value: "hotel" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function AddNewView({ allCampsites }) {
  const { user } = useAuth();
  const [showForm, setShowForm] = useState(true);
  const [tableOptionsVal, setTableOptionsVal] = useState(tableOptions[0]);
  const [shelterOptionsVal, setShelterOptionsVal] = useState(shelterOptions[0]);
  const [typeOfFacilityVal, setTypeOfFacilityVal] = useState(typeOfFacility[0]);
  const [donationOptionsVal, setDonationOptionsVal] = useState(
    donationOptions[0]
  );
  const [waterOptionsVal, setWaterOptionsVal] = useState(waterOptions[0]);
  const [waterSeasonalOptionsVal, setWaterSeasonalOptionsVal] = useState(
    waterSeasonalOptions[0]
  );
  const [showerOptionsVal, setShowerOptionsVal] = useState(showerOptions[0]);
  const [toiletOptionsVal, setToiletOptionsVal] = useState(toiletOptions[0]);
  const [trashServiceOptionsVal, setTrashServiceOptionsVal] = useState(
    trashServiceOptions[0]
  );
  const [electricityOptionsVal, setElectricityOptionsVal] = useState(
    electricityOptions[0]
  );
  const [laundryOptionsVal, setLaundryOptionsVal] = useState(laundryOptions[0]);
  const [foodStorageOptionsVal, setFoodStorageOptionsVal] = useState(
    foodStorageOptions[0]
  );
  const [noTurnAwayVal, setNoTurnAwayVal] = useState(noTurnAway[0]);
  const [wifiOptionsVal, setWifiOptionsVal] = useState(wifiOptions[0]);
  const [hammockOptionsVal, setHammockOptionsVal] = useState(hammockOptions[0]);

  const [lng] = useState(-98.1739304110168);
  const [lat] = useState(39.55519650847471);
  const [zoom] = useState(4);

  const [spotName, setSpotName] = useState("");
  const [spotDesc, setSpotDesc] = useState("");
  const [spotWebsite, setSpotWebsite] = useState("");
  const [spotPhone, setSpotPhone] = useState("");
  const [selectedState, setSelectedState] = useState(states[0]);
  const [selectedCostUnit, setSelectedCostUnit] = useState(costUnit[0]);
  const [spotCost, setSpotCost] = useState(0);

  const [nameError, setNameError] = useState();
  const [descError, setDescError] = useState();
  const [websiteError, setWebsiteError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [costError, setCostError] = useState();
  const [latError, setLatError] = useState();
  const [submissionError, setSubmissionError] = useState();

  const [marker, setMarker] = useState(null);
  const [showSelectionMap, setShowSelectionMap] = useState(true);
  const [pastedCoords, setPastedCoords] = useState("");
  const [pastedCoordsErr, setPastedCoordsErr] = useState("");

  const mapRef = useRef(null);

  const [cursorStyle, setCursorStyle] = useState();
  const [showNamePopup, setShowNamePopup] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState([-100, 40]);
  const [campsiteProperties, setCampsiteProperties] = useState({
    description: "",
  });

  let [selectedFiltersState, setSelectedFiltersState] = useState([
    [
      "all",
      ["!", ["has", "point_count"]],
      [">=", ["coalesce", ["to-number", ["get", "cost"]], 0], 0],
    ],
  ]);

  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "campsiteData",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71", // color for clusters with point_count < 10
        10,
        "#f39c12", // color for clusters with point_count >= 10 and < 100
        100,
        "#f1c40f", // color for clusters with point_count >= 100 and < 750
        750,
        "#51bbd6", // color for clusters with point_count >= 750
      ],
      "circle-radius": [
        "step",
        ["get", "point_count"],
        20, // radius for clusters with point_count < 100
        100,
        30, // radius for clusters with point_count >= 100 and < 750
        750,
        40, // radius for clusters with point_count >= 750
      ],
      "circle-opacity": 1, // Make the circle itself fully opaque
      "circle-stroke-width": 5, // Add a stroke to the circle
      "circle-stroke-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71", // match stroke color to the circle color
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-stroke-opacity": 0.3, // Set the stroke opacity to create a fade effect
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "campsiteData",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredPointLayer = {
    id: "unclustered-point",
    type: "circle",
    source: "campsiteData",
    paint: {
      "circle-color": [
        "match",
        ["get", "type_of_facility"],
        "campground",
        "#fbb03b",
        "h/b campground",
        "#2ecc71",
        "park",
        "#00FFFF",
        "church",
        "#fff",
        "rv park",
        "#b2bec3",
        "no-turn-away",
        "#e056fd",
        "dispersed",
        "#e056fd",
        "business",
        "#ffff00",
        "hostel",
        "#ffff00",
        "hotel",
        "#ffff00",
        /* other */ "#4264fb",
      ],
      "circle-radius": 6,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
    filter: selectedFiltersState[0],
  };

  const translateValues = (val) => {
    if (val === "Yes") {
      return true;
    }

    if (val === "No") {
      return false;
    }

    if (val === "?") {
      return null;
    }
  };

  const nullAndLowercase = (val) => {
    if (val === "?") {
      return null;
    }

    return val.toLowerCase();
  };

  const handleMapClick = (event) => {
    const lat = event.lngLat.lat;
    const lng = event.lngLat.lng;
    setMarker({ lng, lat });
  };

  const addViaCoords = (coordsStr) => {
    const coordsArray = coordsStr.split(",");
    if (
      !coordsStr ||
      coordsArray.length !== 2 ||
      (coordsArray[0] <= -90 && coordsArray[0] >= 90) ||
      isNaN(coordsArray[0]) ||
      isNaN(coordsArray[1])
    ) {
      setPastedCoordsErr(
        "Please paste valid coords. Look at the placeholder text for an example of how the data should look."
      );
      return;
    }
    coordsArray[0] = coordsArray[0].trim();
    coordsArray[1] = coordsArray[1].trim();
    setMarker({ lng: Number(coordsArray[1]), lat: Number(coordsArray[0]) });
    mapRef.current.easeTo({
      center: [coordsArray[1], coordsArray[0]],
      zoom: 13,
      duration: 500,
    });
    setPastedCoordsErr("");
  };

  const addSite = () => {
    window.scrollTo(0, 0);
    setShowSelectionMap(false);
  };

  const editLocation = () => {
    window.scrollTo(0, 0);
    setShowSelectionMap(true);
  };

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const validateForm = () => {
    let hasError = false;

    if (spotName.length < 5) {
      setNameError("Name must be more than 5 characters long.");
      hasError = true;
    } else if (spotName.length > 60) {
      setNameError("Name must be less than 60 characters.");
      hasError = true;
    } else if (spotName.length > 60) {
      setNameError("Name must be less than 60 characters.");
      hasError = true;
    } else {
      setNameError("");
    }

    if (spotDesc.length < 40) {
      setDescError(
        "Description needs to be more than 40 characters. It is currently at: " +
          spotDesc.length
      );
      hasError = true;
    } else if (spotDesc.length > 950) {
      setDescError(
        "Description needs to be less than 950 characters. It is currently at: " +
          spotDesc.length
      );
      hasError = true;
    } else {
      setDescError("");
    }

    if (spotWebsite.length > 0 && !isValidUrl(spotWebsite)) {
      setWebsiteError("Not a valid URL.");
      hasError = true;
    } else if (spotWebsite.length > 200) {
      setWebsiteError("URL is too long. Must be less than 200 characters.");
    } else {
      setWebsiteError("");
    }

    if (spotPhone > 0 && !(spotPhone.replace(/[^\d]/g, "").length === 10)) {
      setPhoneError(
        "Not a valid phone number. Don't include the country code."
      );
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (spotCost < 0) {
      setCostError("The cost needs to be a 0 or greater.");
      hasError = true;
    } else {
      setCostError("");
    }

    if (hasError) {
      setSubmissionError("Fix the errors above.");
    } else {
      setSubmissionError("");
    }

    return hasError;
  };

  async function submitSpot(e) {
    // this stops the data from being sent if there are errors
    if (validateForm()) {
      return;
    }

    e.preventDefault();
    const { data, error } = await supabase
      .from("campsites")
      .insert([
        {
          name: spotName,
          state: selectedState.code,
          no_turn_away: translateValues(noTurnAwayVal),
          description: spotDesc,
          cost: spotCost,
          cost_unit: selectedCostUnit.value,
          donation_based: translateValues(donationOptionsVal),
          phone_number: spotPhone.replace(/[^\d]/g, ""),
          latitude: marker.lat,
          longitude: marker.lng,
          tables: translateValues(tableOptionsVal),
          water: nullAndLowercase(waterOptionsVal),
          showers: nullAndLowercase(showerOptionsVal),
          toilet: nullAndLowercase(toiletOptionsVal),
          trash_service: translateValues(trashServiceOptionsVal),
          electricity: nullAndLowercase(electricityOptionsVal),
          laundry: translateValues(laundryOptionsVal),
          wifi: nullAndLowercase(wifiOptionsVal),
          food_storage: translateValues(foodStorageOptionsVal),
          hammocks: translateValues(hammockOptionsVal),
          type_of_facility: typeOfFacilityVal.value,
          picnic_shelter: translateValues(shelterOptionsVal),
          website_url: spotWebsite,
          submitted_by: user.id,
          approved: false,
          water_is_seasonal: translateValues(waterSeasonalOptionsVal),
        },
      ])
      .select();

    if (error) {
      setSubmissionError(
        "There was an error with the submission: " + error.message
      );
      setTimeout(() => {
        setSubmissionError(null);
      }, 3000);
    }

    if (data) {
      setShowForm(false);
    }
  }

  const onMouseEnter = (e) => {
    setCursorStyle("pointer");
    // this keeps these models from appearing on mobile
    if (isMobile) {
      return;
    }
    // this keeps the name popups from showing onhover when a popup is open
    // if (showPopup) {
    //   return;
    // }

    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ["unclustered-point"],
    });

    if (features.length > 0) {
      const feature = features[0];
      // Handle your feature click event here
      let coordinates = feature.geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      setCampsiteProperties(feature.properties);
      setPopupCoordinates(coordinates);
      setShowNamePopup(true);
    }
  };

  const onMouseLeave = (e) => {
    setShowNamePopup(false);
    setCursorStyle("");
  };

  const theForm = () => {
    return (
      <div>
        <div className="bg-white px-2 md:px-6 py-12 lg:px-8 md:w-9/12 mx-auto">
          {showSelectionMap ? (
            <div>
              <h1 className="text-4xl font-bold pb-4">Add New Location</h1>
              <h2 className="text-3xl text-gray-700 font-bold">Drop a pin</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Click on the map to add the campsite. Or paste the coordinates
                below.
              </p>
              <br />
              <label
                htmlFor="latitude"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Latitude/Longitude
              </label>
              <div className="mt-2 flex gap-4 justify-start">
                <div className="w-3/5 md:w-4/5 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    value={pastedCoords}
                    onChange={(e) => setPastedCoords(e.target.value)}
                    id="latitude"
                    name="latitude"
                    placeholder="39.76559324876378, -104.97843706894767"
                    type="text"
                    className="block w-full border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => addViaCoords(pastedCoords)}
                  className="w-2/5 md:w-1/5 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                  Find Location
                </button>
              </div>
              <p id="lat-error" className="mt-2 text-sm text-red-600">
                {pastedCoordsErr}
              </p>
              <br />
              <div style={{ height: "80vh" }}>
                <Map
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                  reuseMaps
                  width="100%"
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  // style={{ height: mobileHeight }}
                  initialViewState={{
                    longitude: lng,
                    latitude: lat,
                    zoom: zoom,
                  }}
                  cursor={cursorStyle}
                  ref={mapRef}
                  onClick={handleMapClick}
                  interactiveLayerIds={[
                    clusterLayer.id,
                    unclusteredPointLayer.id,
                  ]}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <NavigationControl style={{ marginRight: "25px" }} />
                  <GeolocateControl
                    positionOptions={{ enableHighAccuracy: true }}
                    trackUserLocation={true}
                    showUserLocation={true}
                    auto={true}
                    style={{ marginRight: "25px" }}
                  />
                  <GeocoderControl
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                    position="top-left"
                  />
                  <Source
                    id="campsiteData"
                    type="geojson"
                    data={allCampsites}
                    cluster={true}
                    clusterMaxZoom={14}
                    clusterRadius={30}
                  >
                    <Layer {...clusterLayer} />
                    <Layer {...clusterCountLayer} />
                    <Layer {...unclusteredPointLayer} />
                    {marker && (
                      <Marker
                        latitude={marker.lat}
                        longitude={marker.lng}
                      ></Marker>
                    )}
                    {marker && (
                      <Popup
                        key={marker.lat + marker.lng}
                        latitude={marker.lat}
                        longitude={marker.lng}
                        onClose={() => setMarker(null)}
                      >
                        <div className="p-2">
                          <p className="mb-2 text-sm font-semibold">
                            Coordinates:
                          </p>
                          <p>Longitude: {marker.lng.toFixed(6)}</p>
                          <p className="mb-3">
                            Latitude: {marker.lat.toFixed(6)}
                          </p>
                          <button
                            type="button"
                            onClick={() => addSite()}
                            className="w-full md:w-32 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                          >
                            Add Location
                          </button>
                        </div>
                      </Popup>
                    )}
                    {showNamePopup && (
                      <Popup
                        key={popupCoordinates[0] + popupCoordinates[1]}
                        longitude={popupCoordinates[0]}
                        latitude={popupCoordinates[1]}
                        closeButton={false}
                        maxWidth="240"
                        style={{ textAlign: "center" }}
                      >
                        <h2 className="text-sm">{campsiteProperties.name}</h2>
                      </Popup>
                    )}
                  </Source>
                </Map>
              </div>
            </div>
          ) : (
            <div>
              <h1 className="text-4xl font-bold pb-4">Add New Location</h1>
              <form>
                <div className="space-y-12">
                  <div className="pb-12">
                    <h2 className="text-3xl text-gray-700 font-bold">
                      General
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                      This information will be visible by other cyclists. Please
                      don't share any personal information, and make things as
                      detailed as possible.
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Site Name
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              value={spotName}
                              onChange={(e) => setSpotName(e.target.value)}
                              id="name"
                              name="name"
                              type="text"
                              className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <p
                            id="name-error"
                            className="mt-2 text-sm text-red-600"
                          >
                            {nameError} &nbsp;
                          </p>
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="desc"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Description
                        </label>
                        <div className="mt-2">
                          <textarea
                            id="desc"
                            name="desc"
                            value={spotDesc}
                            onChange={(e) => setSpotDesc(e.target.value)}
                            rows={3}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <p className="mt-3 text-sm leading-6 text-gray-600">
                          Include any information you'd want to know, like cell
                          signal, required permits, check-in process, road
                          conditions on the way to the site.
                        </p>
                        <p
                          id="desc-error"
                          className="mt-2 text-sm text-red-600"
                        >
                          {descError}
                        </p>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="website"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Site Website
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              id="website"
                              name="website"
                              value={spotWebsite}
                              onChange={(e) => setSpotWebsite(e.target.value)}
                              type="url"
                              className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <p
                            id="url-error"
                            className="mt-2 text-sm text-red-600"
                          >
                            {websiteError}
                          </p>
                        </div>
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone Number
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              id="phone"
                              name="phone"
                              type="tel"
                              value={spotPhone}
                              onChange={(e) => setSpotPhone(e.target.value)}
                              className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <p
                            id="phone-error"
                            className="mt-2 text-sm text-red-600"
                          >
                            {phoneError}
                          </p>
                        </div>
                      </div>
                      <div className="sm:col-span-4 sm:max-w-md">
                        <Listbox
                          value={typeOfFacilityVal}
                          onChange={setTypeOfFacilityVal}
                        >
                          <Label className="block text-sm font-medium leading-6 text-gray-900">
                            Type of Facility
                          </Label>
                          <div className="relative mt-2">
                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {typeOfFacilityVal.name}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-400"
                                />
                              </span>
                            </ListboxButton>

                            <ListboxOptions
                              transition
                              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                            >
                              {typeOfFacility.map((facility, index) => (
                                <ListboxOption
                                  key={index}
                                  value={facility}
                                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                >
                                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                    {facility.name}
                                  </span>

                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIcon
                                      aria-hidden="true"
                                      className="h-5 w-5"
                                    />
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                      </div>
                      <div className="sm:col-span-4 sm:max-w-md">
                        <Listbox
                          value={selectedState}
                          onChange={setSelectedState}
                        >
                          <Label className="block text-sm font-medium leading-6 text-gray-900">
                            State
                          </Label>
                          <div className="relative mt-2">
                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {selectedState.name}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-400"
                                />
                              </span>
                            </ListboxButton>

                            <ListboxOptions
                              transition
                              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                            >
                              {states.map((state) => (
                                <ListboxOption
                                  key={state.code}
                                  value={state}
                                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                >
                                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                    {state.name}
                                  </span>

                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIcon
                                      aria-hidden="true"
                                      className="h-5 w-5"
                                    />
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="website"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Cost ($)
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              id="cost"
                              name="cost"
                              value={spotCost}
                              onChange={(e) => setSpotCost(e.target.value)}
                              type="number"
                              className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <p
                            id="cost-error"
                            className="mt-2 text-sm text-red-600"
                          >
                            {costError}
                          </p>
                        </div>
                      </div>
                      <div className="sm:col-span-4 sm:max-w-md">
                        <Listbox
                          value={selectedCostUnit}
                          onChange={setSelectedCostUnit}
                        >
                          <Label className="block text-sm font-medium leading-6 text-gray-900">
                            Cost Unit
                          </Label>
                          <div className="relative mt-2">
                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                              <span className="block truncate">
                                {selectedCostUnit.name}
                              </span>
                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                  aria-hidden="true"
                                  className="h-5 w-5 text-gray-400"
                                />
                              </span>
                            </ListboxButton>

                            <ListboxOptions
                              transition
                              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                            >
                              {costUnit.map((unit, index) => (
                                <ListboxOption
                                  key={index}
                                  value={unit}
                                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                >
                                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                    {unit.name}
                                  </span>

                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                    <CheckIcon
                                      aria-hidden="true"
                                      className="h-5 w-5"
                                    />
                                  </span>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-8 gap-1 my-7 items-center">
                      <div>Donation Based</div>
                      <div className="col-span-6">
                        <fieldset
                          className="cursor-default"
                          aria-label="Choose a memory option"
                        >
                          <RadioGroup
                            value={donationOptionsVal}
                            onChange={setDonationOptionsVal}
                            className="isolate inline-flex rounded-md shadow-sm"
                          >
                            {donationOptions.map((option, index) => (
                              <Radio
                                key={option}
                                value={option}
                                className={classNames(
                                  index === 0 ? "rounded-l-md" : "-ml-px",
                                  index === donationOptions.length - 1
                                    ? "rounded-r-md"
                                    : "",
                                  "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                                )}
                              >
                                {option}
                              </Radio>
                            ))}
                          </RadioGroup>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="space-y-12">
                  <div className="pb-12">
                    <h2 className="text-3xl text-gray-700 font-bold">
                      Location
                    </h2>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-8">
                      <div className="sm:col-span-4">
                        <p>
                          This is how the site you're adding will appear on the
                          map. If it's wrong, please change it.
                        </p>

                        <button
                          type="button"
                          onClick={() => editLocation()}
                          className="w-full md:w-32 rounded-md bg-blue-500 px-3.5 py-2.5 my-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                          Edit location
                        </button>
                        <br />
                        <label
                          htmlFor="latitude"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Latitude
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              value={marker.lat}
                              disabled={true}
                              id="latitude"
                              name="latitude"
                              type="text"
                              className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <p
                            id="lat-error"
                            className="mt-2 text-sm text-red-600"
                          >
                            {latError}
                          </p>
                        </div>
                        <br />
                        <label
                          htmlFor="longitude"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Longitude
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              value={marker.lng}
                              disabled={true}
                              id="longitude"
                              name="longitude"
                              type="text"
                              className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:col-span-4 h-96">
                        <Map
                          mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                          reuseMaps
                          width="100%"
                          mapStyle="mapbox://styles/mapbox/streets-v11"
                          initialViewState={{
                            longitude: marker.lng,
                            latitude: marker.lat,
                            zoom: 13,
                          }}
                          ref={mapRef}
                        >
                          <NavigationControl style={{ marginRight: "25px" }} />
                          <Marker
                            latitude={marker.lat}
                            longitude={marker.lng}
                          />
                        </Map>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <h2 className="text-3xl text-gray-700 font-bold">Amenities</h2>
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Picnic Tables</p>
                </div>
                <div className="col-span-6">
                  <fieldset
                    className="cursor-default"
                    aria-label="Choose a memory option"
                  >
                    <RadioGroup
                      value={tableOptionsVal}
                      onChange={setTableOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {tableOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === tableOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Picnic Shelter</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={shelterOptionsVal}
                      onChange={setShelterOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {shelterOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === shelterOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Water</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={waterOptionsVal}
                      onChange={setWaterOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {waterOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === waterOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "sm:whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Is water seasonal?</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={waterSeasonalOptionsVal}
                      onChange={setWaterSeasonalOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {waterSeasonalOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === waterSeasonalOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Showers</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={showerOptionsVal}
                      onChange={setShowerOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {showerOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === showerOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Toilet</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={toiletOptionsVal}
                      onChange={setToiletOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {toiletOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === toiletOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "sm:whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Electricity</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={electricityOptionsVal}
                      onChange={setElectricityOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {electricityOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === electricityOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Hammock Accessible</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={hammockOptionsVal}
                      onChange={setHammockOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {hammockOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === hammockOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Laundry</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={laundryOptionsVal}
                      onChange={setLaundryOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {laundryOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === laundryOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Wifi</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={wifiOptionsVal}
                      onChange={setWifiOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {wifiOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === wifiOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "sm:whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Food Storage Container</p>
                </div>
                <div class="col-span-8">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={foodStorageOptionsVal}
                      onChange={setFoodStorageOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {foodStorageOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === foodStorageOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>Trash Service</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={trashServiceOptionsVal}
                      onChange={setTrashServiceOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {trashServiceOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === trashServiceOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
                <div class="col-span-2">
                  <p>No Turn Away</p>
                </div>
                <div class="col-span-6">
                  <fieldset
                    aria-label="Choose a memory option"
                    className="cursor-default"
                  >
                    <RadioGroup
                      value={noTurnAwayVal}
                      onChange={setNoTurnAwayVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {noTurnAway.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === noTurnAway.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
              <div class="mt-28 w-full">
                <button
                  type="button"
                  onClick={(e) => submitSpot(e)}
                  className="w-full md:w-32 rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                  Submit Spot
                </button>
                <p id="total-error" className="mt-2 text-sm text-red-600">
                  {submissionError} &nbsp;
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const thankYouMessage = () => {
    return (
      <main
        className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
        style={{ minHeight: "80%" }}
      >
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Thank you for your submission!
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Our moderators are going to look at it shortly, and if approved
            it'll end up on the map. <br />
            If the mods have any questions they'll reach out.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go home
            </Link>
          </div>
        </div>
      </main>
    );
  };

  return showForm ? theForm() : thankYouMessage();
}
