import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { supabase } from "../supabase/client";
import { useAuth } from "../context/AuthProvider";
import { Link } from "react-router-dom";

const formatter = new Intl.DateTimeFormat("en-US", {
  month: "long", // "July"
  day: "numeric", // "25"
  year: "numeric", // "2024"
});

const statuses = {
  Complete: "text-green-700 bg-green-50 ring-green-600/20",
  "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
  Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function RequestsView() {
  const [openItem, setOpenItem] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({
    feature_request_votes: [],
  });
  const [newRequestTitle, setNewRequestTitle] = useState("");
  const [newRequestDesc, setNewRequestDesc] = useState("");
  const [featureRequests, setFeatureRequests] = useState([]);
  const { auth, user } = useAuth();

  async function fetchFeatureRequests() {
    if (user) {
      const { data, error } = await supabase
        .from("feature_requests")
        .select(
          `
            title,
            description,
            id,
            created_at,
            created_by,
            total_votes,
            closed,
            profiles (
                id,
                username
            ),
            feature_request_votes (
                *
            )
        `
        )
        .eq("feature_request_votes.user_id", user.id)
        .order("total_votes", { ascending: false });

      if (error) {
        console.error("Error fetching feature requests:", error);
      } else {
        setFeatureRequests(data);
      }
    } else {
      const { data, error } = await supabase
        .from("feature_requests")
        .select(
          `
            title,
            description,
            id,
            created_at,
            created_by,
            total_votes,
            closed,
            profiles (
                id,
                username
            )
        `
        )
        .order("total_votes", { ascending: false });

      if (error) {
        console.error("Error fetching feature requests:", error);
      } else {
        setFeatureRequests(data);
      }
    }
  }

  useEffect(() => {
    fetchFeatureRequests();
  }, []);

  const handleClick = (e, project) => {
    e.stopPropagation();
    // if (e.target.id.includes("vote")) {
    //   // do the voting
    if (openItem) {
      //   setSelectedRequest({});
      setOpenItem(!openItem);
    } else {
      setSelectedRequest(project);
      setOpenItem(!openItem);
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setOpenForm(false);
    setNewRequestTitle("");
    setNewRequestDesc("");
  };

  async function submitFeatureRequest(e) {
    e.preventDefault();
    const { data, error } = await supabase
      .from("feature_requests")
      .insert([
        {
          title: newRequestTitle,
          description: newRequestDesc,
          created_by: user.id,
        },
      ])
      .select();
    if (data) {
      featureRequests.push({
        id: data[0].id,
        key: data[0].id,
        title: newRequestTitle,
        description: newRequestDesc,
        total_votes: 0,
        created_at: new Date(),
        profiles: { username: "You" },
        feature_request_votes: [],
      });
      setFeatureRequests([...featureRequests]);
      setOpenForm(false);
      setNewRequestTitle("");
      setNewRequestDesc("");
    }
  }

  async function voteForFeatureRequest(e, request) {
    e.stopPropagation();
    if (!auth) return;
    setOpenItem(false);
    let tempRequest = request;

    let frvoteIndex = tempRequest.feature_request_votes.findIndex(
      (e) => e.feature_request_id === tempRequest.id
    );

    let frIndex = featureRequests.findIndex((e) => e.id === tempRequest.id);

    //first: check to see if the fr is already selected and remove it
    if (frvoteIndex > -1) {
      tempRequest.feature_request_votes.splice(frvoteIndex, 1);
      tempRequest.total_votes--;
      featureRequests[frIndex] = tempRequest;
      setFeatureRequests([...featureRequests]);

      const { error } = await supabase
        .from("feature_request_votes")
        .delete()
        .eq("user_id", user.id)
        .eq("feature_request_id", tempRequest.id);
    } else {
      //second: if not, select it
      tempRequest.feature_request_votes.push({
        user_id: user.id,
        feature_request_id: request.id,
      });
      tempRequest.total_votes++;

      featureRequests[frIndex] = tempRequest;

      //   featureRequests.sort((a, b) => b.total_votes - a.total_votes);
      setFeatureRequests([...featureRequests]);

      const { data, error } = await supabase
        .from("feature_request_votes")
        .insert([{ user_id: user.id, feature_request_id: request.id }])
        .select();
    }
  }

  function featureRequestSelected(request) {
    if (!user) {
      return "cursor-not-allowed";
    }

    let fr = request.feature_request_votes.some(
      (e) => e.feature_request_id === request.id
    );

    if (fr) {
      return "text-white bg-orange-500 ring-orange-500 hover:bg-orange-200 hover:ring-orange-200 hover:text-black";
    } else {
      return "bg-white ring-gray-300 hover:bg-gray-50";
    }
  }

  function upvoteButtonText(request) {
    if (!auth) {
      return "Upvote";
    }
    if (request.feature_request_votes.length === 0) return "Upvote";
    let fr = request.feature_request_votes.some(
      (e) => e.feature_request_id === request.id
    );

    if (fr) {
      return "Downvote";
    } else {
      return "Upvote";
    }
  }

  function isUpvoteDisabled() {
    if (!user) {
      return "cursor-not-allowed";
    }
  }

  return (
    <div className="min-h-full">
      <div className="bg-white px-6 py-12 md:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Vote on the next features
          </h1>
          <p className="mt-6 text-xl leading-8">
            There are a lot of things that need to be added to the website. We
            can't do it all at once. Tell us what you want to see added. If you
            see something you support, upvote it so we can prioritize it. We
            want to build something that you love, help us do that.
          </p>
          <div className="text-center pt-5">
            {auth ? (
              <button
                type="button"
                onClick={(e) => setOpenForm(true)}
                className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add a Request
              </button>
            ) : (
              <Link
                as="Button"
                className="rounded-full bg-indigo-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                to="/login"
              >
                Log in to add and vote
              </Link>
            )}
          </div>
          <div className="mt-10">
            <ul role="list" className="divide-y divide-gray-100">
              {featureRequests
                .filter(function (obj) {
                  return !obj.closed;
                })
                .map((request) => (
                  <li
                    key={request.id}
                    className="cursor-pointer flex items-center justify-between gap-x-6 py-5 px-5 hover:bg-gradient-to-t from-slate-50 via-slate-50 to-slate-100 transition-all duration-500 ease-in-out rounded-md"
                    onClick={(e) => handleClick(e, request)}
                  >
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
                          {request.title}
                        </p>
                        {/* <p
                        className={classNames(
                          statuses[request.status],
                          "mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset truncate"
                        )}
                      >
                        {request.status}
                      </p> */}
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Created on{" "}
                          <time dateTime={request.dueDateTime}>
                            {formatter.format(new Date(request.created_at))}
                          </time>
                        </p>
                        <svg
                          viewBox="0 0 2 2"
                          className="h-0.5 w-0.5 fill-current"
                        >
                          <circle r={1} cx={1} cy={1} />
                        </svg>
                        <p className="truncate">
                          Created by {request.profiles.username}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm truncate">
                          {request.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <button
                        key={"vote" + request.id}
                        id="vote"
                        href={request.href}
                        onClick={(e) => voteForFeatureRequest(e, request)}
                        className={
                          "rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset hover:bg-gray-50 block " +
                          featureRequestSelected(request)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                        {request.total_votes}
                        <span className="sr-only">, {request.name}</span>
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
            <h2 className="mt-10 mb-5 text-center text-xl font-bold tracking-tight text-gray-900">
              Closed feature requests
            </h2>
            <ul role="list" className="divide-y divide-gray-100">
              {featureRequests
                .filter(function (obj) {
                  return obj.closed;
                })
                .map((request) => (
                  <li
                    key={request.id}
                    className="flex items-center justify-between gap-x-6 py-5 px-5 hover:bg-gradient-to-t from-slate-50 via-slate-50 to-slate-100 transition-all duration-500 ease-in-out rounded-md"
                  >
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900 truncate">
                          {request.title}
                        </p>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Created on{" "}
                          <time dateTime={request.dueDateTime}>
                            {formatter.format(new Date(request.created_at))}
                          </time>
                        </p>
                        <svg
                          viewBox="0 0 2 2"
                          className="h-0.5 w-0.5 fill-current"
                        >
                          <circle r={1} cx={1} cy={1} />
                        </svg>
                        <p className="truncate">
                          Created by {request.profiles.username}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm truncate">
                          {request.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <button
                        key={"vote" + request.id}
                        id="vote"
                        href={request.href}
                        className={
                          "rounded-md px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm bg-gray-50 block"
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                        {request.total_votes}
                        <span className="sr-only">, {request.name}</span>
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      <Dialog open={openItem} onClose={setOpenItem} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  onClick={(e) => handleClick(e)}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    {selectedRequest.title}
                  </DialogTitle>
                  <div className="mt-2">
                    <p
                      className="text-sm text-gray-500 overflow-scroll max-h-96"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {selectedRequest.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={(e) => voteForFeatureRequest(e, selectedRequest)}
                  className={
                    "inline-flex w-full justify-center rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-300 sm:ml-3 sm:w-auto " +
                    isUpvoteDisabled()
                  }
                >
                  {upvoteButtonText(selectedRequest)}
                </button>
                <button
                  type="button"
                  onClick={(e) => handleClick(e)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Close
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <Dialog open={openForm} onClose={setOpenForm} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  onClick={() => setOpenForm(false)}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <form>
                    <div className="space-y-12 sm:space-y-16">
                      <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">
                          File a request
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                          Please be as detailed as possible in your request.
                          Feel free to include links if that will help. This
                          requests are public.
                        </p>

                        <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="username"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Request Title
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <input
                                  id="request"
                                  onChange={(e) =>
                                    setNewRequestTitle(e.target.value)
                                  }
                                  name="request"
                                  type="text"
                                  value={newRequestTitle}
                                  className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label
                              htmlFor="about"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                            >
                              Describe the functionality
                            </label>
                            <div className="mt-2 sm:col-span-2 sm:mt-0">
                              <textarea
                                id="desc"
                                name="desc"
                                value={newRequestDesc}
                                onChange={(e) =>
                                  setNewRequestDesc(e.target.value)
                                }
                                rows={3}
                                className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 flex items-center justify-end gap-x-6">
                      <button
                        type="button"
                        className="text-sm font-semibold leading-6 text-gray-900"
                        onClick={(e) => handleCancel(e)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={(e) => submitFeatureRequest(e)}
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
