import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../supabase/client";
import { useAuth } from "../context/AuthProvider";

export function CheckinView() {
  const [today, setToday] = useState();
  const [comment, setComment] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const { user } = useAuth();

  const { state } = useLocation();
  const navigate = useNavigate();
  const { properties } = state;

  useEffect(() => {
    setToday(formatDate(new Date()));
  }, []);

  function formatDate(dateToUse) {
    // Get today's date
    const currentDate = dateToUse;

    // Extract the year, month, and day
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  function onChangeData(e) {
    setToday(e.target.value);
  }

  async function submitCheckin(e) {
    e.preventDefault();
    const { data, error } = await supabase
      .from("checkins")
      .insert([
        {
          user_id: user.id,
          campsite_id: properties.id,
          description: comment,
          check_in_date: today,
        },
      ])
      .select();

    if (data) {
      navigate("/campsite/" + properties.id + "-" + properties.name);
    }

    if (error) {
      setErrorMsg(
        "There was an error with your submission. Make sure you've filled out all of the fields accurately"
      );
      setTimeout(() => {
        setErrorMsg(null);
      }, 3000);
    }
  }

  return (
    <div>
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <form>
            <div className="space-y-12">
              <div className="pb-6">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Check-in to {properties.name}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  How was it? Was the water off? Did trains come by at night?
                  Please include anything that you wish someone would've told
                  you. Include price changes and other major updates.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Your Comment
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="comment"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="visited"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Date visited
                    </label>

                    <input
                      type="date"
                      id="visited"
                      name="trip-start"
                      value={today}
                      className="mt-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => onChangeData(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-start gap-x-6">
              <button
                type="submit"
                onClick={(e) => submitCheckin(e)}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Check-in
              </button>
            </div>
            {errorMsg ? (
              <div className="relative block w-full p-4 mb-4 text-base leading-5 text-white bg-red-500 rounded-lg opacity-100 font-regular">
                {errorMsg}
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
