import React, { useEffect, useState, useRef } from "react";
import Map, {
  Source,
  Layer,
  Popup,
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
} from "react-map-gl";
import { isMobile } from "react-device-detect";
import "mapbox-gl/dist/mapbox-gl.css";
import FilterControl from "./FilterControl";
import CampsitePopup from "./CampsitePopup";
import CampsiteInfoDrawer from "./CampsiteInfoDrawer";
import "../../styles/App.css";

const MapboxComponent = ({ allMapData }) => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const mapRef = useRef(null);

  const [lng] = useState(-98.1739304110168);
  const [lat] = useState(39.55519650847471);
  const [zoom] = useState(4);
  const [showPopup, setShowPopup] = useState(false);
  const [showNamePopup, setShowNamePopup] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState([-100, 40]);
  const [campsiteProperties, setCampsiteProperties] = useState({
    description: "",
  });
  const [freeFilterChecked, setFreeFilter] = useState(false);
  const [campsiteInfoOpen, setCampsiteInfoOpen] = useState(false);
  const [cursorStyle, setCursorStyle] = useState();
  const [mobileHeight, setMobileHeight] = useState(800);
  let [selectedFiltersState, setSelectedFiltersState] = useState([
    [
      "all",
      ["!", ["has", "point_count"]],
      [">=", ["coalesce", ["to-number", ["get", "cost"]], 0], 0],
    ],
  ]);

  useEffect(() => {
    if (isMobile) {
      setMobileHeight(700);
    }
  }, []);

  function updateFilters() {
    // this is wonky because the value we are checking
    // hasn't been updated when this function runs. so it's the inverse of what we see on the screen.
    if (freeFilterChecked) {
      setSelectedFiltersState([
        [
          "all",
          ["!", ["has", "point_count"]],
          [">=", ["coalesce", ["to-number", ["get", "cost"]], 0], 0],
        ],
      ]);
    }
    if (!freeFilterChecked) {
      setSelectedFiltersState([
        [
          "all",
          ["!", ["has", "point_count"]],
          ["<=", ["coalesce", ["to-number", ["get", "cost"]], 0], 0],
        ],
      ]);
    }
    setFreeFilter(!freeFilterChecked);
    setShowPopup(false);
  }

  const onMouseEnter = (e) => {
    setCursorStyle("pointer");
    // this keeps these models from appearing on mobile
    if (isMobile) {
      return;
    }
    // this keeps the name popups from showing onhover when a popup is open
    if (showPopup) {
      return;
    }

    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ["unclustered-point"],
    });

    if (features.length > 0) {
      const feature = features[0];
      // Handle your feature click event here
      let coordinates = feature.geometry.coordinates.slice();
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      setCampsiteProperties(feature.properties);
      setPopupCoordinates(coordinates);
      setShowNamePopup(true);
    }
  };

  const onMouseLeave = (e) => {
    setShowNamePopup(false);
    setCursorStyle("");
  };

  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "campsiteData",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71", // color for clusters with point_count < 10
        10,
        "#f39c12", // color for clusters with point_count >= 10 and < 100
        100,
        "#f1c40f", // color for clusters with point_count >= 100 and < 750
        750,
        "#51bbd6", // color for clusters with point_count >= 750
      ],
      "circle-radius": [
        "step",
        ["get", "point_count"],
        20, // radius for clusters with point_count < 100
        100,
        30, // radius for clusters with point_count >= 100 and < 750
        750,
        40, // radius for clusters with point_count >= 750
      ],
      "circle-opacity": 1, // Make the circle itself fully opaque
      "circle-stroke-width": 5, // Add a stroke to the circle
      "circle-stroke-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71", // match stroke color to the circle color
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-stroke-opacity": 0.3, // Set the stroke opacity to create a fade effect
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "campsiteData",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredPointLayer = {
    id: "unclustered-point",
    type: "circle",
    source: "campsiteData",
    paint: {
      "circle-color": [
        "match",
        ["get", "type_of_facility"],
        "campground",
        "#fbb03b",
        "h/b campground",
        "#2ecc71",
        "park",
        "#00FFFF",
        "church",
        "#fff",
        "rv park",
        "#b2bec3",
        "no-turn-away",
        "#e056fd",
        "dispersed",
        "#e056fd",
        "business",
        "#ffff00",
        "hostel",
        "#ffff00",
        "hotel",
        "#ffff00",
        /* other */ "#4264fb",
      ],
      "circle-radius": 6,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
    filter: selectedFiltersState[0],
  };

  // if I'm seeing bugs with this, I should filter for layerId. Not sure how to, but google said it's possible.
  const onClick = (e) => {
    if (e.features.length === 0) return;

    if (e.features[0].layer.id === "clusters") {
      const feature = e.features[0];
      const clusterId = feature.properties.cluster_id;

      // handles click on clusters
      const mapboxSource = mapRef.current.getSource("campsiteData");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        mapRef.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    } else if (e.features[0].layer.id === "unclustered-point") {
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ["unclustered-point"],
      });
      if (features.length > 0) {
        console.log("found feature", features);
        const feature = features[0];
        // Handle your feature click event here
        let coordinates = feature.geometry.coordinates.slice();
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        mapRef.current.easeTo({
          center: feature.geometry.coordinates,
          duration: 500,
        });

        setCampsiteProperties(feature.properties);
        setPopupCoordinates(coordinates);
        setShowPopup(true);
      }
    }
  };

  return (
    <div>
      <CampsiteInfoDrawer
        campsiteInfoOpen={campsiteInfoOpen}
        setCampsiteInfoOpen={setCampsiteInfoOpen}
        properties={campsiteProperties}
        coordinates={popupCoordinates}
      />
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX}
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoom,
        }}
        reuseMaps
        style={{ height: mobileHeight }}
        cursor={cursorStyle}
        width="100%"
        mapStyle="mapbox://styles/mapbox/streets-v11"
        interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        ref={mapRef}
        // cooperativeGestures
      >
        <NavigationControl style={{ marginRight: "25px" }} />
        <GeolocateControl
          positionOptions={{ enableHighAccuracy: true }}
          trackUserLocation={true}
          showUserLocation={true}
          auto={true}
          style={{ marginRight: "25px" }}
        />
        <Source
          id="campsiteData"
          type="geojson"
          data={allMapData}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={30}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
          <FilterControl
            freeFilterChecked={freeFilterChecked}
            updateFiltersFunc={updateFilters}
          />
          {showPopup && (
            <Popup
              key={"campsiteInfo" + popupCoordinates[0] + popupCoordinates[1]}
              longitude={popupCoordinates[0]}
              latitude={popupCoordinates[1]}
              onClose={() => setShowPopup(false)}
              maxWidth="300px"
            >
              <CampsitePopup
                properties={campsiteProperties}
                coordinates={popupCoordinates}
                setCampsiteInfoOpen={setCampsiteInfoOpen}
              />
            </Popup>
          )}

          {showNamePopup && (
            <Popup
              key={popupCoordinates[0] + popupCoordinates[1]}
              longitude={popupCoordinates[0]}
              latitude={popupCoordinates[1]}
              closeButton={false}
              maxWidth="240"
              style={{ textAlign: "center" }}
            >
              <h2 className="text-sm">{campsiteProperties.name}</h2>
            </Popup>
          )}
        </Source>
      </Map>
    </div>
  );
};

export default MapboxComponent;
