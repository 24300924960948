import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import "../../styles/FilterControlStyles.css";
import MapKey from "./MapKey";

export default function FilterControl({
  freeFilterChecked,
  updateFiltersFunc,
}) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  return (
    <div id="filterControlStyles" className="bg-white m-2">
      <div>
        {/* Mobile filter dialog */}
        <Dialog
          className="relative z-40 lg:hidden"
          open={mobileFiltersOpen}
          onClose={setMobileFiltersOpen}
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
            >
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center p-2 text-gray-400 hover:text-gray-500"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                <fieldset>
                  <div className="px-4 pb-2 pt-4">
                    <div className="space-y-6">
                      <div key={"free-0-mobile"} className="flex items-center">
                        <input
                          id={`free-mobile`}
                          name={`free`}
                          checked={freeFilterChecked}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          onChange={(e) => updateFiltersFunc()}
                        />
                        <label
                          htmlFor={`free-mobile`}
                          className="ml-3 text-sm text-gray-500"
                        >
                          Free Sites Only
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="p-4">
                  <MapKey />
                </div>
              </form>
            </DialogPanel>
          </div>
        </Dialog>

        <main className="mx-auto px-4 py-4">
          <div className="lg:grid">
            <aside>
              <h2 className="sr-only">Filters</h2>

              <button
                type="button"
                className="inline-flex items-center lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="text-sm font-medium text-gray-700">
                  Filters/Key
                </span>
                <PlusIcon
                  className="ml-1 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              </button>

              <div className="hidden lg:block">
                <form className="space-y-10 divide-y divide-gray-200">
                  <fieldset>
                    <div className="space-y-3 pt-6">
                      <div key={"free-0"} className="flex items-center">
                        <input
                          id={`free`}
                          name={`1`}
                          checked={freeFilterChecked}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          onChange={(e) => updateFiltersFunc()}
                        />
                        <label
                          htmlFor={`free`}
                          className="ml-3 text-sm text-gray-600"
                        >
                          Free Sites Only
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div className="hidden lg:block py-4">
                <MapKey />
              </div>
            </aside>
          </div>
        </main>
      </div>
    </div>
  );
}
